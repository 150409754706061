import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  onLoginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authSvc: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public _router: Router,
    public _location: Location
  ) { }

  ngOnInit(): void {
    this.initLogin();
  }

  initLogin() {
    this.onLoginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  onLogin() {
    console.log(this.onLoginForm.value);
    if (this.onLoginForm.invalid) {
      return;
    } else {
      this.spinner.show();
      this.authSvc.login(this.onLoginForm.value).subscribe(
        async res => {
          console.log(res);
          await localStorage.setItem('web_token', res['access_token']);
          await this.authSvc.userConnected.next(res['data']);
          this.spinner.hide();
          this.router.navigateByUrl('/dashboard');
          // this.refresh();
        }, err => {
          console.log(err);
          this.spinner.hide();
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
      );
    }
  }

  // refresh(): void {
  //   this._router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
  //     console.log(decodeURI(this._location.path()));
  //     this._router.navigate([decodeURI(this._location.path())]);
  //   });
  // }

 }
