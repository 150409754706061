import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  // {
  //   name: 'Accueil',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer',
  //   // show: 'admin',
  // },
  // {
  //   name: 'Point de Vente',
  //   url: '/vente/ajouter',
  //   icon: 'cil-basket'
  // },
  // {
  //   name: 'Liste Ventes',
  //   url: '/vente/liste',
  //   icon: 'cil-list'
  // },
  // {
  //   name: 'Gestion Magasins',
  //   url: '/magasin',
  //   icon: 'cil-house',
  // },
  // {
  //   name: 'Fournisseurs',
  //   url: '/fournisseurs',
  //   icon: 'cil-people',
  // },
  // {
  //   name: 'Approvisionnements',
  //   url: '/appro',
  //   icon: 'cil-truck',
  // },
  // {
  //   name: 'Catégories',
  //   url: '/categories',
  //   icon: 'cil-tags',
  // },
  // {
  //   name: 'Produits',
  //   url: '/produits',
  //   icon: 'cil-note-add',
  // },
  // {
  //   name: 'Dépenses',
  //   url: '/depenses',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Versements',
  //   url: '/versements',
  //   icon: 'icon-wallet'
  // },
  // {
  //   name: 'Employés',
  //   url: '/employe',
  //   icon: 'icon-user',
  // },
  // {
  //   name: 'Clients',
  //   url: '/client',
  //   icon: 'icon-people'
  // },
  // {
  //   name: 'Mode Paiement',
  //   url: '/modepaiement',
  //   icon: 'icon-star'
  // },
  // {
  //   name: 'Unité Monnétaire',
  //   url: '/devise',
  //   icon: 'cil-money'
  // },
  // {
  //   name: 'Banques',
  //   url: '/banques',
  //   icon: 'icon-wallet'
  // },
];

