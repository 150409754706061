import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './services/auth.guard';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Accueil'
    },
    children: [
      {
        path: 'vente',
        loadChildren: () => import('./views/vente/vente.module').then(m => m.VenteModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./views/magasin/magasin.module').then(m => m.MagasinModule)
      },
      {
        path: 'fournisseurs',
        loadChildren: () => import('./views/fournisseurs/fournisseurs.module').then(m => m.FournisseursModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/accueil/accueil.module').then(m => m.AccueilModule)
      },
      {
        path: 'appro',
        loadChildren: () => import('./views/appro/appro.module').then(m => m.ApproModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('./views/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'fournisseurs',
        loadChildren: () => import('./views/fournisseurs/fournisseurs.module').then(m => m.FournisseursModule)
      },
      {
        path: 'depenses',
        loadChildren: () => import('./views/depenses/depenses.module').then(m => m.DepensesModule)
      },
      {
        path: 'produits',
        loadChildren: () => import('./views/produits/produits.module').then(m => m.ProduitsModule)
      },
      {
        path: 'employe',
        loadChildren: () => import('./views/employe/employe.module').then(m => m.EmployeModule)
      },
      {
        path: 'client',
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'modepaiement',
        loadChildren: () => import('./views/modepaiement/modepaiement.module').then(m => m.ModepaiementModule)
      },
      {
        path: 'message',
        loadChildren: () => import('./views/message/message.module').then(m => m.MessageModule)
      },
      {
        path: 'banques',
        loadChildren: () => import('./views/banques/banques.module').then(m => m.BanquesModule)
      },
      {
        path: 'versements',
        loadChildren: () => import('./views/versements/versements.module').then(m => m.VersementsModule)
      },
      {
        path: 'rapport',
        loadChildren: () => import('./views/rapport/rapport.module').then(m => m.RapportModule)
      },
      {
        path: 'modifpwd',
        loadChildren: () => import('./views/modifpwd/modifpwd.module').then(m => m.ModifpwdModule)
      },
    ]
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
