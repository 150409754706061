import { MagasinModel } from './magasin.model';
export class UserModel {
    constructor(
        public id: string,
        public name: string,
        public phone: string,
        public type: string,
        public statut: string,
        public slug: string,
        public sexe?: string,
        public photo?: string,
        public adresse?: string,
        public email?: string,
        public magasin_id?: number,
        public magasin?: MagasinModel,
    ) {}
}
