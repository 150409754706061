import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  user: UserModel;
  urlBase = environment.urlBase;
  // Menu de l'admin et du gérant
  adminMenu = [
    {
      name: 'Accueil',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Gestion des Stocks',
      url: '/stock',
      icon: 'cil-house',
    },
    {
      name: 'Liste Ventes',
      url: '/vente/liste',
      icon: 'cil-list'
    },
    {
      name: 'Rapport',
      url: '/rapport',
      icon: 'icon-chart'
    },
    {
      name: 'Fournisseurs',
      url: '/fournisseurs',
      icon: 'cil-people',
    },
    {
      name: 'Approvisionnements',
      url: '/appro',
      icon: 'cil-truck',
    },
    {
      name: 'Catégories',
      url: '/categories',
      icon: 'cil-tags',
    },
    {
      name: 'Produits',
      url: '/produits',
      icon: 'cil-note-add',
    },
    {
      name: 'Entrées/Sorties',
      url: '/depenses',
      icon: 'icon-pie-chart'
    },
    {
      name: 'Versements',
      url: '/versements',
      icon: 'icon-wallet'
    },
    {
      name: 'Employés',
      url: '/employe',
      icon: 'icon-user',
    },
    {
      name: 'Clients',
      url: '/client',
      icon: 'icon-people'
    },
    {
      name: 'Mode Paiement',
      url: '/modepaiement',
      icon: 'icon-star'
    },
    {
      name: 'Unité Monnétaire',
      url: '/devise',
      icon: 'cil-money'
    },
    {
      name: 'SMS',
      url: 'message/',
      icon: 'icon-envelope'
    },
    {
      name: 'Banques',
      url: '/banques',
      icon: 'icon-wallet'
    },
  ];
  // Menu des Magasinier
  magasinMenu = [
    {
      name: 'Accueil',
      url: '/dashboard',
      icon: 'icon-speedometer',
      // show: 'admin',
    },
    {
      name: 'Point de Vente',
      url: '/vente/ajouter',
      icon: 'cil-basket'
    },
    {
      name: 'Liste Ventes',
      url: '/vente/liste',
      icon: 'cil-list'
    },
    {
      name: 'Fournisseurs',
      url: '/fournisseurs',
      icon: 'cil-people',
    },
    {
      name: 'Approvisionnements',
      url: '/appro',
      icon: 'cil-truck',
    },
    {
      name: 'Catégories',
      url: '/categories',
      icon: 'cil-tags',
    },
    {
      name: 'Produits',
      url: '/produits',
      icon: 'cil-note-add',
    },
    {
      name: 'Entrées/Sorties',
      url: '/depenses',
      icon: 'icon-pie-chart'
    },
    {
      name: 'Versements',
      url: '/versements',
      icon: 'icon-wallet'
    },
    {
      name: 'Employés',
      url: '/employe',
      icon: 'icon-user',
    },
    {
      name: 'Clients',
      url: '/client',
      icon: 'icon-people'
    },
    {
      name: 'Rapport',
      url: '/rapport',
      icon: 'icon-chart'
    },
    {
      name: 'SMS',
      url: '/message',
      icon: 'icon-envelope'
    },
  ];

  constructor(
    private authsrv: AuthService,
    private router: Router
    // private employesrv: Employe
  ) {}

  ngOnInit() {
    this.user = this.authsrv.userConnected.getValue();
    console.log(navItems);
    if (this.user.type === 'employe') {
      this.navItems = this.magasinMenu;
    }
    if (this.user.type === 'admin' || this.user.type === 'gerant') {
      this.navItems = this.adminMenu;
    }
      // this.navItems = navItems;
      //   setTimeout(() => {
      //       this.navItems = [
      //         {
      //           name: 'Accueil',
      //           url: '/dashboard',
      //           icon: 'icon-speedometer',
      //           show: 'admin',
      //         },
      //     ];
      //   }, 1000);
  }
  //
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    Swal.fire({
      title: 'Confirmez vous',
      text: 'la deconnexion ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#dd3333',
      confirmButtonText: 'Oui'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/dashboard/logout');
      }
    });
  }


}
