import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userConnected: BehaviorSubject<UserModel> = new BehaviorSubject(
    new UserModel(null, null, null, null, null, null, null, null)
  );

  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('web_token')}`
    })
  };

  login(user: any) {
    return this.http.post(environment.urlApi + 'login', user);
  }

  loadUser(webToken: any) {
    if (webToken) {
      return this.http.get(environment.urlWeb + 'loaduser', webToken);
    } else {
      return this.http.get(environment.urlWeb + 'loaduser', this.httpOptions);
    }
  }

  logout(httpOptions: any = null) {
    if (httpOptions) {
      return this.http.post(environment.urlWeb + 'logout', [], httpOptions);
    } else {
      return this.http.post(environment.urlWeb + 'logout', [], this.httpOptions);
    }
  }

  register(data, httpOption = null) {
    const httpOptions = {
      headers: new HttpHeaders({
          'enctype': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(environment.urlWeb + 'register', data, httpOptions);
  }

  modifPassword(data, httpOption = null) {
    const httpOptions = {
      headers: new HttpHeaders({
          'enctype': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(environment.urlWeb + 'modifpassword', data, httpOptions);
  }
}
