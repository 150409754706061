import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user: UserModel;

  constructor(
    private authSvc: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
        (resolve, reject) => {
              this.authSvc.userConnected.subscribe(
                res => {
                  this.user = res;
                }
              );
              if (this.user.id) {
                resolve(true);
              } else {
                const webToken = localStorage.getItem('web_token');
                if (webToken != null) {
                  const httpOptions = {
                      headers: new HttpHeaders({
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${webToken}`
                      })
                  };
                  this.authSvc.loadUser(httpOptions).subscribe(
                    res => {
                      console.log(res);
                      this.authSvc.userConnected.next(res['data']);
                      console.log(this.authSvc.userConnected.getValue());
                      resolve(true);
                    }, err => {
                      console.log(err);
                      this.router.navigateByUrl('/login', {replaceUrl: true});
                    }
                  );
                } else {
                  this.router.navigateByUrl('/login', {replaceUrl: true});
                }
              }
        }
    );
}
}
