// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   urlBase: 'http://localhost:8000',
//   urlApi: 'http://localhost:8000/api/',
//   urlWeb: 'http://localhost:8000/api/web/'
// };

// export const environment = {
//   production: false,
//   urlBase: 'http://192.168.43.3:8000',
//   urlApi: 'http://192.168.43.3:8000/api/',
//   urlWeb: 'http://192.168.43.3:8000/api/web/'
// };


export const environment = {
  production: true,
  urlBase: 'https://apistock.soditagui.com/',
  urlApi: 'https://apistock.soditagui.com/api/',
  urlWeb: 'https://apistock.soditagui.com/api/web/'
};

// export const environment = {
//   production: true,
//   urlBase: 'https://api.hellobabykids.com/',
//   urlApi: 'https://api.hellobabykids.com/api/',
//   urlWeb: 'https://api.hellobabykids.com/api/web/'
// };
